/*! main.js | Bulkit | CSS Ninja */

/* ==========================================================================
Core JS file
========================================================================== */

"use strict";

$(document).ready(function ($) {

	//5. Init Layout
	initNavbar();
	initLandingNavbar();
	initMobileMenu();
	initLandingMobileMenu();
	initEcommerceNavbar();
	initNavbarDropdown();
	initSidebar();
	initThemeSwitcher();
	initBackgroundImages();

	//6. Components
	initSlider();
	initDropdowns();
	initTabsNav();
	initNavigationTabs();
	initVerticalTabs();
	initMediaCards();
	initTiltCards();
	initPopovers();
	initTooltips();
	initModals();
	initCounters();
	initSimpleAccordion();
	initAccordions();
	initToasts();
	initCountdown();

	//10. Demo
	initDemo();
	initScrollspyNav();
	initParallax();
	initBackToTop();
	
	//11. Utility functions
	initGitem();
	initAnchorScroll();
	initQuickview();
	initScrollReveal();
	initMarquee();
	
	//12. Page specific methods
	initMockup();
	initClientsCarousel();
	initPeopleCarousel();
	initCustomCarousel();
	initCarousel();
	initLandingCarousel();
	initTestimonials();
	initCharacterTestimonials();
	initPricing();
	initPricingCarousel();
	initTabbedPricing();
	initFreelancerPricing();
	initSwitchPricing();
	initBoxedPricing();
	initOnePagePricing();
	//initBlog();
	initSearchBox();
	initNavigationDots();
	initFaq();
	initAuth();
	initAnimations();
	initCanvas();
	initParticles();
	initAnimatedSvg();
	initChatWidget();
	initContactToggler();
	initGoogleMap();
	initMapBox();
	initCodeTabs();
})